import { render, staticRenderFns } from "./CIconPercent.vue?vue&type=template&id=9363d3b6&scoped=true"
var script = {}
import style0 from "./CIconPercent.vue?vue&type=style&index=0&id=9363d3b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9363d3b6",
  null
  
)

export default component.exports