import { render, staticRenderFns } from "./CIconCarouselRight.vue?vue&type=template&id=b1e8e1a8&scoped=true"
var script = {}
import style0 from "./CIconCarouselRight.vue?vue&type=style&index=0&id=b1e8e1a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e8e1a8",
  null
  
)

export default component.exports