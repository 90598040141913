import { render, staticRenderFns } from "./ProductInfoSelector.vue?vue&type=template&id=02370932&scoped=true"
import script from "./ProductInfoSelector.vue?vue&type=script&lang=ts"
export * from "./ProductInfoSelector.vue?vue&type=script&lang=ts"
import style0 from "./ProductInfoSelector.vue?vue&type=style&index=0&id=02370932&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02370932",
  null
  
)

export default component.exports