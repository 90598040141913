import { render, staticRenderFns } from "./LandingLayout.vue?vue&type=template&id=0c313097&scoped=true"
import script from "./LandingLayout.vue?vue&type=script&lang=ts"
export * from "./LandingLayout.vue?vue&type=script&lang=ts"
import style0 from "./LandingLayout.vue?vue&type=style&index=0&id=0c313097&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c313097",
  null
  
)

export default component.exports