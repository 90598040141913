import { render, staticRenderFns } from "./CIconLock.vue?vue&type=template&id=f9bbb8cc&scoped=true"
var script = {}
import style0 from "./CIconLock.vue?vue&type=style&index=0&id=f9bbb8cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9bbb8cc",
  null
  
)

export default component.exports